module services {
    export module master {
        export class goodsDeclarationAmendmentRequestSettingService implements interfaces.master.IGoodsDeclarationAmendmentRequestSettingService {
            static $inject = ["$resource", "ENV","$http","$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getList(): ng.resource.IResourceClass<interfaces.master.IGoodsDeclarationAmendmentRequestSettingsDisplay> {
                return this.$resource<interfaces.master.IGoodsDeclarationAmendmentRequestSettingsDisplay>(this.ENV.DSP_URL + "GoodsDeclarationAmendmentRequestSetting/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }

            getGoodsDeclarationAmendmentRequestSettingDetail(): ng.resource.IResourceClass<interfaces.master.IGoodsDeclarationAmendmentRequestSettingsDisplay> {
                return this.$resource<interfaces.master.IGoodsDeclarationAmendmentRequestSettingsDisplay>(this.ENV.DSP_URL + "GoodsDeclarationAmendmentRequestSetting/GetGoodsDeclarationAmendmentRequestSettingDetail",
                    {
                        vatId: "@vatId",
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

        
            saveGoodsDeclarationAmendmentRequestSetting(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationAmendmentRequestSetting/SaveGoodsDeclarationAmendmentRequestSetting", {
                });
            }   

            getGoodsDeclarationAmendmentRequestSettingsExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Payment/GetPaymentsExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));


                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'GoodsDeclarationAmendmentRequestSettings.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

        }
    }
    angular.module("app").service("goodsDeclarationAmendmentRequestSettingService", services.master.goodsDeclarationAmendmentRequestSettingService);
}